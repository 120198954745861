import { createTheme } from "@mui/material/styles";

// Temas en Solicitud en src/config/theme.js
// Temas en BOA / BOU en src/@fuse/default-settings/FuseDefaultSettings en func: mainThemeVariations
const FINMUTUAL = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#93c572",
      iconColor: "#93c572",
      checkColor: "#000000",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#C4D89D",
    },
    highlight: { main: "#93c572" },
    secondary: {
      main: "#c4d89d",
    },
    background: {
      default: "#f5f6f8",
    },
    button: {
      // main: "#93c572",
      main: "#6527BE",
      light: "#1c1b1b30",
      light2: "#1c1b1b60",
    },
    text: {
      main: "#000000",
      contrast: "#FFFFFF",
    },
    spinner: "#000000",
    spinnerBackground: "#93c572",
  },
  banner: true,
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "#93c572",
});

const DEMO = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0FD08B",
      iconColor: "#0FD08B",
      checkColor: "#0FD08B",
      numColor: "#2A1846",
      completedColor: "#2A1846",
      backgroundStepColor: "#0FD08B",
    },
    highlight: { main: "#2A1846" },
    secondary: {
      main: "#2A1846",
    },
    background: {
      default: "#F8F8F6",
    },
    button: {
      main: "#1c1b1b",
      light: "#1c1b1b30",
      light2: "#1c1b1b60",
    },
    text: {
      main: "#000000",
      contrast: "#000000",
    },
    spinner: "#000000",
    spinnerBackground: "#0FD08B",
  },
  banner: true,
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "",
});

const TEMPLATE = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#3159c1",
      iconColor: "#3159c1",
      checkColor: "#3159c1",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#3159c1" },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#EAEAEA",
    },
    button: {
      main: "#3159c1",
      light: "#3159c130",
      light2: "#3159c160",
    },
    text: {
      main: "#000000",
      contrast: "#333333",
    },
    spinner: "#ffffff",
    spinnerBackground: "#3159c1",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "",
});

const DEFAULT = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
  },
  palette: {
    background: {
      default: "#eee6fd",
      iconColor: "#1f1f1f",
      checkColor: "#1f1f1f",
      numColor: "#ffffff",
      completedColor: "#000000",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#eee6fd" },
    primary: {
      main: "#eee6fd",
    },
    secondary: {
      main: "#DAB0EA",
    },
    button: {
      main: "#3159c1",
      light: "#3159c130",
      light2: "#3159c160",
    },
    text: {
      main: "#1f1f1f",
      contrast: "#1f1f1f",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "",
});

export {
  DEFAULT,
  DEMO,
  FINMUTUAL,
  TEMPLATE
};
