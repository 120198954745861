import finmutualConf from "../services/reducers/configReducer/config/finmutualConf";
import {
  isDemoFlavour,
  isLocalhost,
  isStaging,
} from "../services/reducers/configReducer/config/rootConf";
import { FINMUTUAL, TEMPLATE } from "./theme";

let theme;
if (isLocalhost() || (isStaging() && isDemoFlavour())) {
  theme = FINMUTUAL;
} else if (finmutualConf.is()) theme = FINMUTUAL;
else theme = TEMPLATE;

export { theme };
