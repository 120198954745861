import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsSolicitudAmpliacion from "../../../hooks/useIsSolicitudAmpliacion";
import useIsSoliciudRefinanciacion from "../../../hooks/useIsSolicitudRefinanciacion";
import { sucursalFormat } from "../../../utils";
import Error from "../../Error/Error";
import Help from "../../Help/Help";
import Spinner from "../../Spinner/Spinner";
import StepFour from "../StepFour/StepFour";
import StepOne from "../StepOne/StepOne";
import StepThree from "../StepThree/StepThree";
import StepTwo from "../StepTwo/StepTwo";
import "./Core.css";
import CoreAmpliacion from "./CoreAmpliacion";
import CoreRefinanciacion from "./CoreRefinanciacion";
import { ColorlibConnector, ColorlibStepIcon } from "./CustomStepper";
import { useCore, useGetSolicitud } from "./hooks";

export const isPasarela = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("pasarela");
};

const Core = () => {
  const theme = useTheme();
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const {
    config,
    config: {
      jumpData,
      displayStepper,
      displayStepProgress,
      displayMain,
      displayNavbar,
      displayError,
      displayHelp,
      dataset,
      step: { step1Text, step2Text, step3Text, step4Text },
    },
  } = useSelector((state) => state);

  const stepTitle = config.step.title;
  const getTitle = (title) => (stepTitle ? stepTitle : title);

  const { stepToJump, stepsToJump, stepNull, goToStep } = useCore();
  const { getSolicitud, updateStore, getDataToStep, getPasarelaData } =
    useGetSolicitud();
  const fromBoa = () => {
    return urlParams.get("t");
  };
  const fromLink = () => {
    return urlParams.get("tk");
  };
  // volver a poner 0 cuando se terminen los cambios
  const [mainStep, setMainStep] = useState(0);

  const isRefinanciacion = useIsSoliciudRefinanciacion();
  const isAmpliacion = useIsSolicitudAmpliacion();

  React.useEffect(() => {
    if (!fromBoa() && !fromLink() && !isPasarela()) {
      dispatch({ type: "done/jumping" });
      stepNull();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const jump = () => {
      if (jumpData.token !== "") {
        dispatch({ type: "show/spinner" });
        dispatch({ type: "hide/side" });
        getSolicitud(jumpData)
          .then((response) => {
            updateStore(response.data);
            goToStep(
              setMainStep,
              jumpData.step,
              response.data.isVigente,
              response.data.isExpiredByPreAsignado,
              response.data.estadoSolicitud,
              response.data.generationType,
              response.data.hasNewMontoCancelacion
            );
          })
          .then(() => {
            dispatch({ type: "hide/side" });
            dispatch({ type: "done/jumping" });
            dispatch({ type: "hide/spinner" });
            if (jumpData.step !== "STEP_0") dispatch({ type: "hide/main" });
          })
          .catch((error) => {
            console.log(error);
            dispatch({ type: "hide/spinner" });
          });
      }
    };
    const remoteSignature = () => {
      const token = urlParams.get("tk");
      dispatch({ type: "hide/side" });
      getDataToStep(token)
        .then((response) => {
          dispatch({
            type: "remote/signature",
            payload: { token },
          });
          updateStore(response.data);
          goToStep(setMainStep, "STEP_5");
        })
        .then(() => {
          dispatch({ type: "hide/side" });
          dispatch({ type: "done/jumping" });
          dispatch({ type: "hide/main" });
        })
        .catch((error) => console.log(error));
    };
    const pasarela = async () => {
      dispatch({ type: "show/spinner" });
      dispatch({ type: "hide/side" });
      dispatch({ type: "show/display-step-progress" });
      try {
        const response = await getPasarelaData(urlParams.get("pasarela"));
        updateStore(response.data);
        goToStep(setMainStep, "STEP_1");
        dispatch({ type: "done/jumping" });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "show/error",
          payload: {
            errorTitle: "Ha ocurrido un error",
            errorBody: "No se puede continuar la solicitud",
          },
        });
      }
      dispatch({ type: "hide/spinner" });
    };
    if (fromBoa()) {
      jump();
    } else if (fromLink()) {
      remoteSignature();
    } else if (isPasarela()) {
      pasarela();
    } else {
      stepNull();
    }
    // eslint-disable-next-line
  }, [jumpData]);

  const handleMainNextStep = () => {
    setMainStep((prev) => prev + 1);
  };

  const steps = [
    {
      label: "Tus datos",
      component: (
        <StepOne
          handleMainNextStep={handleMainNextStep}
          title={getTitle(step1Text)}
          stepNumber={1}
          stepToJump={stepToJump}
          stepsToJump={stepsToJump}
        />
      ),
    },
    {
      label: "Confirmar",
      component: (
        <StepTwo
          handleMainNextStep={handleMainNextStep}
          title={getTitle(step2Text)}
          stepNumber={2}
          stepToJump={stepToJump}
          stepsToJump={stepsToJump}
        />
      ),
    },
    {
      label: "Validación",
      component: (
        <StepThree
          handleMainNextStep={handleMainNextStep}
          title={getTitle(step3Text)}
          stepNumber={3}
          stepToJump={stepToJump}
          stepsToJump={stepsToJump}
        />
      ),
    },
    {
      label: "Final",
      component: (
        <StepFour
          handleMainNextStep={handleMainNextStep}
          title={getTitle(step4Text)}
          stepNumber={4}
          stepToJump={stepToJump}
          stepsToJump={stepsToJump}
        />
      ),
    },
  ];
  const favicon = document.querySelector("[rel=icon]");
  favicon.href = `./assets/images/${dataset.icon.toLowerCase()}_favicon.webp`;

  return (
    <>
      <Spinner />
      <Box
        sx={{
          // padding: { xs: "0.5rem", sm: "3rem", md: "3rem", lg: "4rem" },
          marginTop: { xs: "2rem" },
          paddingTop: { xs: "1rem", sm: "1.5rem" },
          marginBottom: { xs: "0rem", sm: "1.5rem" },
          height: { xs: "100vh", sm: "auto" },
          overflow: "auto",
        }}
      >
        <Box display="flex" flexDirection="column" gap={4}>
          {!displayNavbar && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Collapse
                sx={{
                  transition:
                    "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
                }}
                orientation="vertical"
                in={displayMain}
              >
                <img
                  src={`./assets/images/${dataset.icon.toLowerCase()}_main_icon.webp`}
                  srcSet={`./assets/images/${dataset.icon.toLowerCase()}_main_icon.webp`}
                  alt="Logo"
                  style={{
                    width: dataset.main_icon_width || "200px",
                    alignSelf: "center",
                  }}
                  loading="lazy"
                />
              </Collapse>
            </Box>
          )}

          <Collapse
            sx={{
              transition:
                "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
            }}
            orientation="vertical"
            in={displayMain}
          >
            {!theme.banner ? (
              <Box
                sx={{ color: theme.palette.text.contrast }}
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <Typography
                  variant="h3"
                  className="core_title"
                  fontWeight="700"
                  letterSpacing="3px"
                >
                  {dataset.subject_flavor || "¡Hola!"}
                </Typography>
                <Typography variant="h4" className="core_subtitle">
                  {dataset.texto_flavor}
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifySelf: "center",
                  marginTop: { xs: "3rem", md: "1rem" },
                }}
              >
                <img
                  alt="Banner"
                  srcSet={`./assets/images/${dataset.icon.toLowerCase()}_banner.webp`}
                  src={`./assets/images/${dataset.icon.toLowerCase()}_banner.webp`}
                  style={{
                    width: "100%",
                    justifySelf: "center",
                    borderRadius: "16px",
                  }}
                  loading="lazy"
                />
              </Box>
            )}
          </Collapse>

          {displayError && <Error />}
          {displayHelp && <Help />}
          {displayStepper && (
            <>
              <Collapse
                sx={{
                  transition:
                    "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
                }}
                orientation="vertical"
                in={displayStepProgress}
              >
                <Stepper
                  activeStep={mainStep}
                  alternativeLabel
                  connector={<ColorlibConnector />}
                >
                  {steps.map(
                    (step, index) =>
                      index < 3 && (
                        <Step key={index}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <Typography
                              sx={{
                                color:
                                  mainStep === index
                                    ? theme.palette.primary.completedColor
                                    : theme.palette.primary.numColor,
                              }}
                            >
                              {step.label}
                            </Typography>
                          </StepLabel>
                        </Step>
                      )
                  )}
                </Stepper>
              </Collapse>

              <Box display="flex" flexDirection="column" gap={1}>
                {dataset.operador && dataset.operadorData.mail !== "" && (
                  <Card
                    sx={{
                      height: { sm: "auto" },
                      boxShadow: "none",
                    }}
                  >
                    <CardContent
                      sx={{
                        padding: "6px 12px 6px 6px !important",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        {dataset.operadorData.sucursal.img && (
                          <Grid item>
                            <img
                              style={{ width: "12rem", height: "4rem" }}
                              src={dataset.operadorData.sucursal.img}
                              alt="Sucursal"
                            />
                          </Grid>
                        )}

                        <Grid item>
                          <Typography align="right" sx={{ fontSize: "15px" }}>
                            Fecha:
                          </Typography>
                          <Typography align="right" sx={{ fontSize: "15px" }}>
                            {moment().format("DD/MM/YYYY hh:mm")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography align="right" sx={{ fontSize: "15px" }}>
                            Usuario:
                          </Typography>
                          <Typography align="right" sx={{ fontSize: "15px" }}>
                            {dataset.operadorData.mail}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography align="right" sx={{ fontSize: "15px" }}>
                            Sucursal:
                          </Typography>
                          <Typography align="right" sx={{ fontSize: "15px" }}>
                            {sucursalFormat(
                              dataset.operadorData.sucursal.id,
                              dataset.operadorData.sucursal.nombre
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )}

                {isAmpliacion && <CoreAmpliacion />}
                {isRefinanciacion && <CoreRefinanciacion />}

                <Card
                  sx={{
                    padding: {
                      xs: "0.5rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                    },
                    height: { xs: "100%", sm: "auto" },
                    boxShadow: "none",
                  }}
                >
                  <CardContent>{steps[mainStep].component}</CardContent>
                </Card>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Core;
