/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Typography, useTheme, Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { showEmotions } from "../../utils";
import Reactions from "../Reactions/Reactions";
import "./Spinner.css";

const Spinner = () => {
  const spinnerText = useSelector((state) => state.config.spinnerText);
  const theme = useTheme();
  const dataset = useSelector((state) => state.config.dataset);
  const displaySpinner = useSelector((state) => state.config.displaySpinner);
  const showHappyFace = useSelector((state) => state.config.showHappyFace);
  const showSadFace = useSelector((state) => state.config.showSadFace);
  const showVeryHappyFace = useSelector(
    (state) => state.config.showVeryHappyFace
  );
  const showExpectationFace = useSelector(
    (state) => state.config.showExpectationFace
  );
  const showThinkingFace = useSelector(
    (state) => state.config.showThinkingFace
  );
  const showFRFace = useSelector((state) => state.config.showFRFace);
  const [showAReaction, setShowAReaction] = React.useState(false);
  const [textEmotion, setTextEmotion] = React.useState("");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (showVeryHappyFace) {
      setTextEmotion("¡Felicidades!");
      setShowAReaction(true);
    } else if (showExpectationFace) {
      setTextEmotion("Estamos buscando las ofertas ideales para vos");
      setShowAReaction(true);
    } else if (showSadFace) {
      setTextEmotion(
        "Lo siento. En este momento no tenemos una oferta para vos."
      );
      setShowAReaction(true);
    } else if (showFRFace) {
      setTextEmotion(
        "¡Estás a un solo paso! Necesitamos validar tu identidad y para eso te pediremos acceso a tu cámara"
      );
      setShowAReaction(true);
    } else if (showHappyFace) {
      setTextEmotion(
        dataset.nombre
          ? `¡Hola ${dataset.nombre}! Te estábamos esperando`
          : "¡Hola! Te estábamos esperando"
      );
      setShowAReaction(true);
    } else {
      setShowAReaction(false);
    }
    if (!showEmotions(dataset.sucursal)) {
      setShowAReaction(false);
    }
  }, [
    showHappyFace,
    showThinkingFace,
    showSadFace,
    showExpectationFace,
    showVeryHappyFace,
    showFRFace,
    displaySpinner,
  ]);
  return (
    <Box
      className="outsideSpinner"
      sx={{
        visibility: displaySpinner || showAReaction ? "visible" : "hidden",
        opacity: displaySpinner || showAReaction ? "1" : "0",
        transition:
          displaySpinner || showAReaction
            ? "opacity 0.5s linear"
            : "visibility 0s 0.5s, opacity 0.5s linear",
        backgroundColor: theme.palette.spinnerBackground,
      }}
    >
      <Box className="containerSpinner">
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {!showAReaction ? (
            <CircularProgress
              sx={{ color: theme.palette.spinner }}
              size="5rem"
            />
          ) : (
            <Reactions />
          )}
        </Box>
        {showAReaction ? (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              marginTop: "2rem",
              color: theme.palette.text.contrast,
            }}
          >
            {textEmotion}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              marginTop: "2rem",
              color: theme.palette.text.contrast,
            }}
          >
            {spinnerText === "" ? "Aguardá un momento..." : spinnerText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Spinner;
