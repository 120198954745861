import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsSoliciudRefinanciacion from "../../../hooks/useIsSolicitudRefinanciacion";
// import { solicitudGenerationType } from "../../../services/reducers/configReducer/solicitudGeneration";
import useIsSolicitudAmpliacion from "../../../hooks/useIsSolicitudAmpliacion";
import {
  stepTwoA,
  stepTwoA2,
  stepTwoA3,
} from "../../../services/steps.services";
import { errorSetter, sumWithoutDecimals } from "../../../utils";
import { DateInput, SelectInput } from "../../Inputs";
import Error from "../../Warnings/Error";
import BasicGrid from "./components/BasicGrid";
// eslint-disable-next-line

const internalSteps = {
  none: 0,
  selectOferta: 1,
  selectFechaVencimiento: 2,
  selectDestinoDinero: 3,
  selectCuota: 4,
};

const StepOneD = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.config.dataset);
  // const displaySpinner = useSelector((state) => state.config.displaySpinner);
  const primerVencimiento = useRef(null);
  const [open, setOpen] = useState(false);
  const isRefinanciacion = useIsSoliciudRefinanciacion();

  // const fake = fakeOfertas
  // const [ofertas, setOfertas] = React.useState(fake);

  const [ofertas, setOfertas] = useState(
    dataset.ofertas.map((item, i) => ({
      ...item,
      montoSeleccionado: null,
      id: i + 1,
    }))
  );

  const [ofertaSeleccionada, setOfertaSeleccionada] = useState(null);
  const [cuotas, setCuotas] = useState(null);
  const [numCuota, setNumCuota] = useState(dataset.cuotas);
  const [montoCuota, setMontoCuota] = useState(dataset.montoCuota);
  const [plazoCuota, setPlazoCuota] = useState();
  const [monedaExpresionCuota, setMonedaExpresionCuota] = useState("UYU");
  const [fechaMinMax, setFechaMinMax] = useState({ min: "", max: "" });
  const [destinos, setDestinos] = useState(dataset.destinos);
  const [destino, setDestino] = useState(null);
  const [opcion, setOpcion] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("Ha ocurrido un error");
  const [showDestinos, setShowDestinos] = useState(false);
  const [step, setStep] = useState(internalSteps.selectOferta);
  const [dateError, setDateError] = useState(null);
  const isAmpliacion = useIsSolicitudAmpliacion();

  const findCuota = (ofertas) => {
    const equalsOferta = ofertas.find((item) => item.seleccionado);

    if (
      !equalsOferta.listPlazoCuota ||
      equalsOferta.listPlazoCuota.length === 0
    ) {
      setOpenError(true);
      setErrorText("No hay cuotas disponibles");
      return null;
    }
    return equalsOferta.listPlazoCuota;
  };

  const canSelectFechaVencimiento = () => fechaMinMax.min !== fechaMinMax.max;

  useEffect(() => {
    dispatch({ type: "hide/spinner" });
    dispatch({ type: "hide/emotions" });
    setOpen(true);
    setCuotas([]);
    setDestinos([]);
    setOfertaSeleccionada(null);

    if (isAmpliacion) {
      dispatch({
        type: "step/title/add",
        payload: "Monto adicional a solicitar",
      });
      dispatch({ type: "core/ampliacion/text/prev-vale/show" });
    }

    if (isRefinanciacion) {
      dispatch({
        type: "step/title/add",
        payload: "Monto total a refinanciar",
      });
      dispatch({ type: "core/refinanciacion/text/prev-vale/show" });

      dispatch({ type: "show/spinner" });
      const oferta = ofertas[0];
      handleSeleccionarOferta(oferta, dataset.montoHeredado);
      setTimeout(() => dispatch({ type: "hide/spinner" }), 200); // Fix a timing issue when is loading cuotas when fecha vencimiento is only one day.
    }

    // eslint-disable-next-line
    return () => {
      if (isAmpliacion) {
        dispatch({ type: "step/title/delete" });
        dispatch({ type: "core/ampliacion/text/prev-vale/hide" });
      }
      if (isRefinanciacion) {
        dispatch({ type: "step/title/delete" });
        dispatch({ type: "core/refinanciacion/text/prev-vale/hide" });
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!ofertaSeleccionada) {
      handleClean();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ofertaSeleccionada]);

  useEffect(() => {
    // @description Set value by default of Fecha vencimiento & plazoCuota
    if (fechaMinMax.min && fechaMinMax.max && ofertaSeleccionada) {
      let fecha = fechaMinMax.min.replaceAll("T00", "T11"); // Set time to 11:00
      fecha = new Date(fecha.replaceAll("T01", "T11")); // Set time to 11:00

      // If can't select fecha vencimiento, automatic set plazoCuota
      if (!canSelectFechaVencimiento()) handleFechaVencimiento(fecha);
      // If can select fecha vencimiento, only set fecha vencimiento
      else {
        let fecha = new Date();
        // default fecha vencimiento is 1 month
        fecha.setMonth(fecha.getMonth() + 1);
        // if fecha vencimiento is greater than max date, set max date
        const maxFecha = new Date(fechaMinMax.max.replaceAll("T00", "T11"));
        if (fecha > maxFecha) fecha = maxFecha;
        onChangeFechaVencimiento(fecha);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaMinMax]);

  useEffect(() => {
    const show = () => {
      if (destinos && destinos.length > 0 && ofertaSeleccionada) {
        if (destinos.length > 1) {
          return setShowDestinos(true);
        } else {
          const unicoDestino = destinos[0];
          setOpcion(
            unicoDestino.destinoLabel
              ? unicoDestino.destinoLabel
              : unicoDestino.destinoDinero
          );
          handleDestinos(destinos[0].destinoDinero);
          return setShowDestinos(false);
        }
      }
      return setShowDestinos(false);
    };
    show();
    // eslint-disable-next-line
  }, [destinos]);

  /* onChanges */
  const onChangeFechaVencimiento = (value) => {
    setPlazoCuota(value);
    nextInternalStep(internalSteps.selectOferta);

    const minDate = new Date(fechaMinMax.min.replaceAll("T00", "T11"));
    minDate.setHours(0, 0, 0, 0);
    const maxDate = new Date(fechaMinMax.max.replaceAll("T00", "T11"));
    maxDate.setHours(0, 0, 0, 0);
    const date = new Date(value);
    date.setHours(0, 0, 0, 0);

    // if date is invalid, set error
    if (isNaN(date)) {
      let rangoStr = "por favor elija entre el rango ";
      rangoStr += moment(minDate).format("DD/MM/YYYY");
      rangoStr += " - " + moment(maxDate).format("DD/MM/YYYY");
      setDateError("La fecha de vencimiento no es válida, " + rangoStr);
    } else if (date < minDate) {
      const minStr = moment(minDate).format("DD/MM/YYYY");
      setDateError("La fecha de vencimiento no puede ser menor a " + minStr);
    } else if (date > maxDate) {
      const maxStr = moment(maxDate).format("DD/MM/YYYY");
      setDateError("La fecha de vencimiento no puede ser mayor a " + maxStr);
    } else setDateError(null);
  };

  /* Internal steps */
  const isInLastInternalStep = () => step === internalSteps.selectCuota;

  function handleInternalSteps() {
    if (step === internalSteps.selectFechaVencimiento)
      return handleFechaVencimiento(plazoCuota);
    else if (step === internalSteps.selectDestinoDinero)
      return handleDestinos(destino);
    // else if (step === internalSteps.selectCuota) handleFechaVencimiento(numCuota);
  }

  function nextInternalStep(currentInternalStep) {
    if (!currentInternalStep || internalSteps.none === currentInternalStep)
      setStep(internalSteps.selectOferta);
    else if (currentInternalStep === internalSteps.selectOferta)
      setStep(internalSteps.selectFechaVencimiento);
    else if (currentInternalStep === internalSteps.selectFechaVencimiento) {
      if (showDestinos) setStep(internalSteps.selectDestinoDinero);
      else setStep(internalSteps.selectCuota);
    } else if (currentInternalStep === internalSteps.selectDestino)
      setStep(internalSteps.selectCuota);
  }

  /* Handlers */
  const handleDestinos = (e) => {
    setDestino(e);
    setMontoCuota("");
    setNumCuota("");
    setOpenError(false);
    setCuotas([]);
    dispatch({ type: "show/spinner" });
    stepTwoA2(
      dataset.id,
      e,
      dataset.cedula,
      dataset.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token
    )
      .then((response) => {
        setCuotas(findCuota(response.data.ofertas));
        nextInternalStep(internalSteps.selectDestinoDinero);
        dispatch({ type: "show/spinner" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "show/spinner" });
        errorSetter(setOpenError, setErrorText, err);
      });
  };

  const handleFechaVencimiento = (value) => {
    setDestino(null);
    setOpenError(false);
    setPlazoCuota(value);
    dispatch({ type: "show/spinner" });
    return stepTwoA(
      dataset.id,
      ofertaSeleccionada.montoSeleccionado,
      ofertaSeleccionada.monedaExpresion,
      moment(value).format("yyyy-MM-DD"),
      dataset.cedula,
      dataset.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token
    )
      .then((response) => {
        setDestinos(response.data.destinoDineroVOLista);
        nextInternalStep(internalSteps.selectFechaVencimiento);
        dispatch({ type: "show/spinner" });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "show/spinner" });
        errorSetter(setOpenError, setErrorText, err);
      });
  };

  const handleSeleccionarOferta = (oferta, monto) => {
    if (monto > oferta.maximoMonto || monto < oferta.minimoMonto) {
      const set = () => {
        setOpenError(true);
        setErrorText("Monto no valido");
      };
      return set();
    }
    if (destinos.length > 0) {
      setDestinos([]);
    }
    setOpenError(false);
    setDestino(null);
    setCuotas([]);
    setPlazoCuota("");
    setOpen(false);
    setFechaMinMax({ min: oferta.fechaMinima, max: oferta.fechaMaxima });
    setOfertaSeleccionada({ ...oferta, montoSeleccionado: monto });
    const select = (item) => {
      if (item.id === oferta.id) {
        return { ...item, seleccionado: true, montoSeleccionado: monto };
      }
      return { ...item, seleccionado: false, montoSeleccionado: 0 };
    };
    setOfertas(ofertas.map((item) => select(item)));
    nextInternalStep(internalSteps.selectOferta);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Handle internal steps
    if (!isInLastInternalStep()) return handleInternalSteps();

    // Change step(page)
    dispatch({ type: "show/spinner" });
    return stepTwoA3(
      dataset.canal,
      dataset.id,
      numCuota,
      destino,
      dataset.cedula,
      dataset.telefono,
      dataset.sucursal,
      dataset.token
    )
      .then((response) => {
        dispatch({ type: "show/spinner" });
        dispatch({
          type: "step/two-a",
          payload: {
            donde: destino,
            opcion: opcion,
            cuotas: numCuota,
            destinos: destinos,
            monto: ofertaSeleccionada.montoSeleccionado,
            numCuotas: cuotas,
            destinoElegido: destino,
            montoCuota: montoCuota,
            conOfertas: response.data.estadoSolicitud,
            primerVencimiento: moment(plazoCuota).format("YYYY-MM-DD"),
            monedaExpresion: ofertaSeleccionada.monedaExpresion,
            monedaExpresionCuota: monedaExpresionCuota,
            adicionales: {
              list: response.data.productoFinancieroAdicionalLista,
              maxToSelect:
                response.data.productoFinanciero.cantMaxSeleccionable,
              minToSelect:
                response.data.productoFinanciero.cantMinSeleccionable,
              autoselect: response.data.productoFinanciero.auto_seleccionar_yn,
            },
          },
        });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
      })
      .catch((err) => {
        dispatch({ type: "show/spinner" });
        errorSetter(setOpenError, setErrorText, err);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangeMonto = () => {
    if (ofertaSeleccionada && step === 1) {
      setOfertaSeleccionada(null);
    }
    nextInternalStep(internalSteps.none);
  };

  const handleClean = () => {
    setOpenError(false);
    setOpen(true);
    setCuotas([]);
    setDestinos([]);
    setDestino(null);
    setPlazoCuota("");
    setMontoCuota("");
    setOfertas(ofertas.map((item) => ({ ...item, seleccionado: false })));
    setDateError(null);
  };

  return (
    <>
      {!isRefinanciacion && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
            Por favor selecciona una oferta
          </Alert>
        </Snackbar>
      )}
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={4}
        onSubmit={(e) => handleSubmit(e)}
      >
        <BasicGrid
          ofertas={ofertas}
          handleSeleccionarOferta={handleSeleccionarOferta}
          handleChangeMonto={handleChangeMonto}
        />

        {dataset.operador &&
          dataset.operadorData.mail !== "" &&
          isAmpliacion &&
          ofertaSeleccionada &&
          ofertaSeleccionada.montoSeleccionado &&
          dataset.montoHeredado && (
            <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
              El monto total a financiar es de $
              {sumWithoutDecimals(
                ofertaSeleccionada.montoSeleccionado,
                dataset.montoHeredado
              )}
            </Alert>
          )}

        {step >= internalSteps.selectFechaVencimiento && (
          <FormControl required error={!!dateError} fullWidth>
            <DateInput
              onChange={(value) => onChangeFechaVencimiento(value)}
              value={plazoCuota}
              refference={primerVencimiento}
              error={!!dateError}
              required={canSelectFechaVencimiento()}
              label={
                canSelectFechaVencimiento()
                  ? "¿Qué fecha de vencimiento preferís?"
                  : "Fecha vencimiento de la primera cuota:"
              }
              disabled={!canSelectFechaVencimiento()}
              min={
                new Date(
                  fechaMinMax.min
                    .replaceAll("T00", "T11")
                    .replaceAll("T01", "T11")
                )
              }
              max={
                new Date(
                  fechaMinMax.max
                    .replaceAll("T00", "T11")
                    .replaceAll("T01", "T11")
                )
              }
            />
            <FormHelperText>{dateError}</FormHelperText>
          </FormControl>
        )}

        {step >= internalSteps.selectDestinoDinero && showDestinos && (
          <FormControl component="fieldset" required sx={{ width: "100%" }}>
            <FormLabel component="legend" required>
              ¿Dónde querés recibir el dinero?
            </FormLabel>
            <RadioGroup
              aria-label="Selecciona una opción"
              value={destino}
              name="donde"
              required
              onChange={(e) => {
                handleDestinos(e.target.value);
              }}
            >
              {destinos.map((destino, index) => (
                <div
                  onClick={() =>
                    setOpcion(
                      destino.destinoLabel
                        ? destino.destinoLabel
                        : destino.destinoDinero
                    )
                  }
                  key={index}
                >
                  <FormControlLabel
                    value={
                      destino.destinoDinero
                        ? destino.destinoDinero
                        : dataset.destinos[index]
                    }
                    control={<Radio color="highlight" />}
                    label={
                      <Typography>
                        {destino.destinoLabel
                          ? destino.destinoLabel
                          : dataset.destinos[index]}
                      </Typography>
                    }
                  />
                  <Typography fontSize="small">
                    {destino.destinosTooltip && destino.destinosTooltip}
                  </Typography>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        )}

        {step >= internalSteps.selectCuota && cuotas && cuotas.length > 0 && (
          <FormControl required sx={{ width: "100%" }}>
            <SelectInput
              label="¿En cuántas cuotas?"
              id="cuotas"
              name="cuotas"
              value={numCuota}
              required
              disabled={Boolean(cuotas.length === 0)}
              onChange={(e) => {
                setNumCuota(Number(e.target.value));
                setMontoCuota(
                  cuotas.find((cuota) => cuota.plazo === Number(e.target.value))
                    .montoCuota
                );
                setMonedaExpresionCuota(
                  cuotas.find((cuota) => cuota.plazo === Number(e.target.value))
                    .monedaExpresionCuota
                );
              }}
              items={cuotas.map((cuota, index) => (
                <MenuItem key={index} value={cuota.plazo}>
                  {cuota.plazo} cuotas de{" "}
                  {ofertaSeleccionada && cuota.monedaExpresionCuota}{" "}
                  {cuota.montoCuota}
                </MenuItem>
              ))}
            />
          </FormControl>
        )}

        {!ofertaSeleccionada || (
          <Button
            variant="contained"
            type="submit"
            color="button"
            disabled={
              (step === internalSteps.selectFechaVencimiento &&
                (!plazoCuota || dateError)) ||
              (step === internalSteps.selectDestino && !destino) ||
              (step === internalSteps.selectCuota && !numCuota)
            }
            sx={{ width: "min-content", alignSelf: "flex-end" }}
          >
            {/* Mientras no seleccione cuota debe decir Seleccionar */}
            {step !== internalSteps.selectCuota ? "Seleccionar" : "Continuar"}
          </Button>
        )}
        <Error text={errorText} show={openError} setShow={setOpenError} />
      </Box>
    </>
  );
};

export default StepOneD;
